// @flow

export const SpStatus = {
  Enable: 'enable',
  Delinquent: 'delinquent',
  NotSigned: 'not signed',
};

export const UserProfileMode = {
  Buyer: 0,
  Supplier: 1,
};

export type UserProfileModeEnum = $Keys<typeof UserProfileMode>;

export const EntityType = {
  Company: 'Company',
  SoleProprietor: 'Sole Proprietor',
  Partnership: 'Partnership',
  Trust: 'Trust',
};

export type EntityTypeEnum = $Keys<typeof EntityType>;

export const DeedStatus = {
  NotAvailable: 'Not Available',
  UnderReview: 'Under Review',
  Approved: 'Approved',
  Declined: 'Declined',
};

export type DeedStatusEnum = $Keys<typeof DeedStatus>;

export type Account = {
  roles: Array<string>,
  name: string,
  isDirect: boolean,
  isSupplier: boolean,
  isBuyer: boolean,
  gcAccountId: string,
  spStatus: $Keys<typeof SpStatus>,
  entityType: ?$Keys<typeof EntityType>,
  deedStatus: ?$Keys<typeof DeedStatus>,
  accountReadiness: number,
  isAto: boolean,
  shiftReferralUrl: string,
  isAllowedToRegisterForBusinessOverdraft: Boolean,
};

export const BuyerRequestType = {
  KnownContact: 'KnownContact',
  UnknownContact: 'UnknownContact',
};

export type BuyerRequestTypeEnum = $Keys<typeof BuyerRequestType>;

export type Contact = {
  phone: string,
  lastName: string,
  firstName: string,
  gcContactId: string,
};

export const accountReadinessFlags = {
  none: 0,
  digitalKycPass: 1,
  pepResultPass: 2,
  amlResultPass: 4,
};

export type Supplier = {
  abn: string,
  gcAccountId: string,
  accountName: string,
  isAto: boolean,
};

export type File = {
  type: string,
  name: string,
  size: number,
  error: string,
  uploaded: boolean,
  timestamp: Date,
};

export type ReferralAccount = {
  hashedAccountId: any,
  term: any,
  termPeriod: any,
};

export type MoreActionsContextMenuItem = {
  actionText: string,
  actionType: string,
  action: (params: {
    actionType: string,
    supplierGcAccountId: string,
    buyerAccountId: string,
    buyerAccountName: string,
    buyerAccountAbn: string,
    term: number,
    termsPeriod: string,
  }) => void,
  permissionTo: string,
  subject: string,
};

export type PreApprovalDetail = {
  isPreApprovedLimit: boolean,
  preApprovedLimit: number,
  preApprovedExpiryDate: Date,
};

export type ExtendInvoiceRequest = {
  invoiceId: string,
  gcAccountId: string,
  isWeekly: boolean,
  term: number,
  transactionFee: number,
  repaymentDate?: Date,
};
