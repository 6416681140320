// @flow
import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import getCapitalTheme from './theme/themeOverride';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import StyledSnackbarProvider from './components/StyledSnackbarProvider';
import { GlobalLoadingIndicator } from './components/GlobalLoadingIndicator';
import { ReferralAccountFromQueryString } from './components/ReferralAccountFromQueryString';
import { TelemetryProvider } from './components/TelemetryProvider';
import Mouseflow from './components/insights/Mouseflow';
import FetchLoadingIndicator from './components/FetchLoadingIndicator';

type Props = {
  children: React.Node,
};

const GlobalWrapper = (props: Props) => (
  <>
    <ReferralAccountFromQueryString />
    <TelemetryProvider>
      <Mouseflow />
      <ThemeProvider theme={getCapitalTheme}>
        <GlobalErrorBoundary>
          <StyledSnackbarProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GlobalLoadingIndicator />
              <FetchLoadingIndicator />
              <CssBaseline />
              {props.children}
            </MuiPickersUtilsProvider>
          </StyledSnackbarProvider>
        </GlobalErrorBoundary>
      </ThemeProvider>
    </TelemetryProvider>
  </>
);

export default GlobalWrapper;
