import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { type LDContext, type LDFlagSet, LDProvider, useFlags as useFlagsUntyped, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { type PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UAParser from 'ua-parser-js';

import { getAppInsights } from './telemetryUtils';
import { selectContact } from '../components/InitialData/selectors';
import startupConfigSelectors from '../components/StartupConfig/selectors';

import { type StartupConfigValues } from '../lib/startupConfig';

/**
 * A typed LD `useFlags` hook. See {@link useFlagsUntyped}.
 *
 * @returns All the flags configured in your LaunchDarkly project.
 */
export const useFlags = useFlagsUntyped as () => FeatureFlags;

/**
 * The available feature flags.
 */
export type FeatureFlags = LDFlagSet & {
  scheduledPayments?: boolean;
};

/**
 * Root provider for feature flags through LaunchDarkly's React integration. This component also
 * sets up a syncing mechanism that provides the current user (if available) and the device information
 * as context to LaunchDarkly.
 */
export function FlagsProvider({ children }: PropsWithChildren<{ sdkKey?: string }>) {
  const configuration: StartupConfigValues = useSelector(startupConfigSelectors.selectConfiguration);

  if (!configuration) return null;

  return (
    <LDProvider clientSideID={configuration.launchDarklyClientSideKey}>
      {children}
      <LDContextSync />
    </LDProvider>
  );
}

/**
 * Component used to sync the LD context. Note that when `identify` through the LD client,
 * the current connection to LD is dropped and established again, causing the feature flags to be reevaluated.
 * This is considered an expensive operation, so avoid making changes often by making sure dependencies of the
 * `useEffect` are memoized.
 */
export function LDContextSync() {
  const ldClient = useLDClient();
  const contact = useSelector(selectContact);

  useEffect(() => {
    let sync = true;
    async function syncContext() {
      try {
        await ldClient!.waitForInitialization(5);
        const ua = getUserAgent();
        const context: LDContext = {
          kind: 'multi',
          device: {
            key: ua.ua,
            os: ua.os.name,
            browser: ua.browser.name,
          },
        };

        if (contact?.gcContactId) {
          context.user = {
            key: contact.gcContactId,
            name: `${contact.firstName} ${contact.lastName}`,
          };
        }

        if (!sync) {
          return;
        }

        ldClient?.identify(context);
      } catch (e) {
        getAppInsights()?.trackException({
          id: 'launch-darkly-initialization',
          exception: e,
          severityLevel: SeverityLevel.Error,
        });
      }
    }

    if (!ldClient) {
      return;
    }

    syncContext();

    return () => {
      sync = false;
    };
  }, [contact, ldClient]);

  return null;
}

let ua: UAParser.IResult;
function getUserAgent() {
  if (!ua) {
    ua = new UAParser(navigator.userAgent).getResult();
  }

  return ua;
}
