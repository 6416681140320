// @flow
import { SESSION_STORAGE_STARTUP_CONFIG } from '../constants';

export type AttachmentConfig = {
  maxFilesCount: number,
  maxFileSizeInMb: number,
};

export type ThreatMetrixConfig = {
  domain: string,
  orgId: string,
  pageId: string,
};

export type FeatureConfiguration = {
  checkoutSupportEnabled: Boolean,
  invoicePayoff: Boolean,
  weeklyRepaymentUpliftEnabled: Boolean,
};

export type StartupConfigValues = {
  identityServerUrl: string,
  registrationUiUrl: string,
  shiftServiceUrl: string,
  abnSearchServiceUrl: string,
  instrumentationKey: string,
  invoiceAttachment: AttachmentConfig,
  trustDeedAttachment: AttachmentConfig,
  mfKey: string,
  widgetsUrl: string,
  shiftOnlineUrl: string,
  threatMetrix: ThreatMetrixConfig,
  featureConfiguration: FeatureConfiguration,
  launchDarklyClientSideKey: string,
};

let config: StartupConfigValues = {
  identityServerUrl: '',
  registrationUiUrl: '',
  shiftServiceUrl: '',
  abnSearchServiceUrl: '',
  instrumentationKey: '',
  invoiceAttachment: {
    maxFileSizeInMb: 5,
    maxFilesCount: 10,
  },
  trustDeedAttachment: {
    maxFileSizeInMb: 5,
    maxFilesCount: 10,
  },
  mfKey: '',
  widgetsUrl: '',
  shiftOnlineUrl: '',
  threatMetrix: {
    domain: '',
    orgId: '',
    pageId: '',
  },
  featureConfiguration: {},
  launchDarklyClientSideKey: '',
};

const set = (value: StartupConfigValues): void => {
  if (window && window.sessionStorage) {
    window.sessionStorage.setItem(SESSION_STORAGE_STARTUP_CONFIG, JSON.stringify(value));
    config = value;
    return;
  }

  throw Error('sessionStorage is undefined');
};

const get = (): StartupConfigValues => {
  if (window && window.sessionStorage) {
    if (!config.instrumentationKey) {
      const json = window.sessionStorage.getItem(SESSION_STORAGE_STARTUP_CONFIG);
      if (json) {
        config = JSON.parse(json);
      }
    }

    return config;
  }

  throw Error('sessionStorage is undefined');
};

export default {
  set,
  get,
};
