/* istanbul ignore file */
import { AnyAction, Reducer, combineReducers, configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import type { History } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { reducer as formReducer } from 'redux-form';

import accountSelectionMiddleware from './accountSelectionMiddleware';
import { reducer as abilityReducer } from '../components/AbilityProvider';
import { reducer as accountSelectMenuReducer } from '../components/AccountSelectMenu';
import { reducer as accountSetupCheckReducer } from '../components/AccountSetupCheck';
import { reducer as addBankConnectFormReducer } from '../components/AddBankConnectForm';
import { reducer as addBankSelectAccountFormReducer } from '../components/AddBankSelectAccountForm';
import { reducer as balanceSummaryReducer } from '../components/BalanceSummary';
import { reducer as bankConnectCardsReducer } from '../components/BankConnectCards';
import { reducer as bankSearchReducer } from '../components/BankSearch';
import { reducer as globalLoadingIndicatorReducer } from '../components/GlobalLoadingIndicator';
import { reducer as initialDataReducer } from '../components/InitialData';
import { reducer as multifactorAuthenticationReducer } from '../components/MultiFactorAuthenticationForm';
import { reducer as pendingInvoicesReducer } from '../components/PendingInvoices';
import { reducer as startupConfigReducer } from '../components/StartupConfig';
import { reducer as supplierRequestsReducer } from '../components/SupplierRequests';
import { reducer as userSelectProfileModeReducer } from '../components/UserSelectProfileMode';
import { reducer as buyerDashboardReducer } from '../pages/Buyer/Dashboard';
import { reducer as addBuyerInvoiceReducer } from '../pages/Buyer/OldAddInvoice';
import { reducer as reviewReducer } from '../pages/Buyer/Review';
import { reducer as supplierRequestReducer } from '../pages/Buyer/SupplierRequest';
import { reducer as loginReducer } from '../pages/Login';
import { reducer as settingsReducer } from '../pages/Settings';

const createRootReducer = (history: History<unknown>) =>
  // @ts-expect-error
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    ...globalLoadingIndicatorReducer,
    ...startupConfigReducer,
    ...loginReducer,
    ...initialDataReducer,
    ...accountSelectMenuReducer,
    ...settingsReducer,
    ...userSelectProfileModeReducer,
    ...bankConnectCardsReducer,
    ...bankSearchReducer,
    ...multifactorAuthenticationReducer,
    ...addBankConnectFormReducer,
    ...addBankSelectAccountFormReducer,
    ...balanceSummaryReducer,
    ...pendingInvoicesReducer,
    ...reviewReducer,
    ...addBuyerInvoiceReducer,
    ...accountSetupCheckReducer,
    ...supplierRequestsReducer,
    ...supplierRequestReducer,
    ...abilityReducer,
    ...buyerDashboardReducer,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

const rootReducer =
  (history: History<unknown>): Reducer<RootState> =>
  (state, action) => {
    let appState = state;
    if (action.type.indexOf('GLOBAL_RESET') > -1 || history.location.pathname === '/logout') {
      appState = undefined;
    }

    return createRootReducer(history)(appState, action);
  };

/* istanbul ignore next */
function logActions() {
  return (next: (action: AnyAction) => unknown) => (action: AnyAction) => {
    if (window && window.console && window.console.log) {
      const { type, payload } = action;
      window.console.log('[Redux Dispatch]', new Date().valueOf(), type, payload);
    }
    return next(action);
  };
}

export function configureStore(history: History<unknown>, initialState: Partial<RootState>) {
  const middleware = [logActions, routerMiddleware(history), accountSelectionMiddleware];

  return configureReduxStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(...middleware),
    preloadedState: initialState,
    devTools: { trace: true, traceLimit: 25 },
  });
}

export type AppStore = ReturnType<typeof configureStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
