import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import selectors from './selectors';
import initialDataSelectors from '../../../components/InitialData/selectors';
import addInvoiceSelectors from '../OldAddInvoice/selectors';

const mapStateToProps = createSelector(
  selectors.selectInvoice,
  selectors.selectFeesTermsAndPaymentTypes,
  selectors.selectInstallmentTermInWeeks,
  selectors.selectPaymentDate,
  initialDataSelectors.selectSelectedGcAccountId,
  addInvoiceSelectors.selectIsAllowedJoinBrokerNetwork,
  (invoice, feesTermsAndPaymentTypes, installmentTermInWeeks, paymentDate, gcAccountId, isAllowedJoinBrokerNetwork) => ({
    invoice,
    feesTermsAndPaymentTypes,
    installmentTermInWeeks,
    paymentDate,
    gcAccountId,
    isAllowedJoinBrokerNetwork,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
