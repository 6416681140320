// @flow
import { PARTITION } from './constants';
import addInvoice from '../OldAddInvoice/selectors';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectInvoice = (state: any, props: any) => {
  if (props.match.params.id) {
    return selectPartition(state).invoice;
  }
  return addInvoice.selectInvoice(state);
};
const selectFeesTermsAndPaymentTypes = (state: any) => selectPartition(state).feesTermsAndPaymentTypes;
const selectExtensionTermsAndFees = (state: any) => selectPartition(state).extensionTermsAndFees;
const selectPaymentDate = (state: any) => selectPartition(state).paymentDate;
const selectInstallmentTermInWeeks = (state: any) => selectPartition(state).installmentTermInWeeks;

export default {
  selectPartition,
  selectInvoice,
  selectFeesTermsAndPaymentTypes,
  selectPaymentDate,
  selectInstallmentTermInWeeks,
  selectExtensionTermsAndFees,
};
