import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import BrokerNetworkIcon from './svg/BrokerNetworkIcon';
import initialDataSelectors from '../components/InitialData/selectors';
import { PORTAL_TYPE } from '../constants';
import { getSingleSignOnUrlAsync } from '../lib/ssoUtils';
import { useAuth } from '../api/authhooks';
import { defaultHandleError } from '../lib/apiHelpers';
import addInvoiceSelectors from '../pages/Buyer/OldAddInvoice/selectors';

const useStyles = makeStyles((theme) => ({
  holder: {
    boxShadow: '0 0 0 0 !important',
    border: '1px solid',
    borderColor: '#F6E3B9',
    backgroundColor: '#FDF8ED',
    marginTop: '32px',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.subtitle3.fontSize,
    },
    textAlign: 'center',
    padding: '5px',
    fontWeight: 500,
    paddingTop: '20px',
    paddingBottom: '5px',
  },
  content: {
    textAlign: 'center',
  },
  buttonHolder: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '12px',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
    textAlign: 'center',
    backgroundColor: '#EDC364',
    '&:hover': {
      //you want this to be the same as the backgroundColor above
      backgroundColor: '#EDC364',
    },
    color: '#FFFFFF',
    width: '80%',
  },
  learnMore: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
    fontSize: '12px',
    textAlign: 'center',
  },
  openIcon: {
    fontSize: 15,
    marginLeft: '9px',
    marginBottom: '1px',
  },
  networkIcon: {
    textAlign: 'center',
  },
}));

const JoinBrokerNetwork = () => {
  const classes = useStyles();
  const { renew } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const activeAccount = useSelector((state) => initialDataSelectors.selectActiveAccount(state));
  const isTentativeJoinBrokerNetworkWidget = useSelector((state) => addInvoiceSelectors.selectIsAllowedJoinBrokerNetwork(state));

  const handleSsoWithTokenRenew = async (gcAccountId) => {
    try {
      await renew();
      const ssoUrl = await getSingleSignOnUrlAsync(gcAccountId, PORTAL_TYPE.SHIFTONLINE);
      window.open(ssoUrl, '_blank');
    } catch {
      defaultHandleError({ enqueueSnackbar }, undefined);
    }
  };

  const handleLinkToExternalClick = async (gcAccountId) => {
    if (activeAccount && activeAccount.isAllowedJoinBrokerNetwork) {
      const ssoUrl = await getSingleSignOnUrlAsync(gcAccountId, PORTAL_TYPE.SHIFTONLINE);
      window.open(ssoUrl, '_blank');
    } else if (isTentativeJoinBrokerNetworkWidget) {
      await handleSsoWithTokenRenew(gcAccountId);
    }
  };

  const isJoinBrokerNetworkWidgetEnabled = (activeAccount && activeAccount.isAllowedJoinBrokerNetwork) || isTentativeJoinBrokerNetworkWidget;

  if (!isJoinBrokerNetworkWidgetEnabled) return <></>;

  return (
    <Paper className={classes.holder} data-testid='uia-joinBrokerNetwork'>
      <Grid className={classes.container} container>
        <Grid className={classes.totalRemaining} item sm={12} xs={6}>
          <Grid container justify='center'>
            <BrokerNetworkIcon />
          </Grid>
          <Typography className={classes.heading}>Join our broker network</Typography>
          <Typography className={classes.content}>Support your clients&apos; business needs with a platform built for brokers.</Typography>
          <Grid className={classes.buttonHolder}>
            <Button
              className={classes.button}
              onClick={() => handleLinkToExternalClick(activeAccount.gcAccountId)}
              data-testid='uia-joinBrokerNetworkButton'
            >
              Join now
              <OpenInNewIcon className={classes.openIcon} />
            </Button>
          </Grid>
          <Grid className={classes.learnMore}>
            <Link
              href='https://www.shift.com.au/brokers/broker-owned-model/'
              rel='noopener norreferrer'
              target='_blank'
              data-testid='uia-learnMoreLink'
            >
              Learn more
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JoinBrokerNetwork;
