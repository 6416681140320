// @flow
import * as React from 'react';

import type { StartupConfigValues } from '../../lib/startupConfig';
import { initSession } from '../../lib/threatmetrix';

type Props = {
  configuration: StartupConfigValues,
  isConfigurationSet: boolean,
  getStartupConfig: () => any,
};

const StartupConfig = ({ children, configuration, isConfigurationSet, getStartupConfig }: Props) => {
  React.useEffect(() => {
    if (isConfigurationSet) {
      initSession(configuration?.threatMetrix);
    } else {
      getStartupConfig();
    }
  }, [isConfigurationSet, configuration, getStartupConfig]);

  if (!isConfigurationSet || !configuration) {
    return null;
  }

  return children;
};

export default StartupConfig;
