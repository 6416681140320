// @flow
import { PARTITION } from './constants';
import accountSelectMenu from '../AccountSelectMenu/selectors';
import type { ReduxState } from './reducer';
import { type JwtValues } from '../../api/claims';
import { SpStatus, EntityType, DeedStatus, type Contact, type Account, type ReferralAccount } from '../../types';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectJwtValues = (state: any): ?JwtValues => selectPartition(state).jwtValues;
export const selectContact = (state: any): ?Contact => selectPartition(state).contact;
const selectFirstName = (state: any) => {
  const contact = selectContact(state);
  return contact ? contact.firstName : '';
};
const selectLastName = (state: any) => {
  const contact = selectContact(state);
  return contact ? contact.lastName : '';
};
const selectAccounts = (state: any) => selectPartition(state).accounts;
const selectIsAllKycCompleted = (state: any): boolean => selectPartition(state).isAllKycCompleted;
const selectReferralAccount = (state: any): ?ReferralAccount => selectPartition(state).referralAccount;
const selectActiveAccount = (state: any): ?Account => {
  const accounts = selectAccounts(state);
  let activeAccount = null;
  if (accounts) {
    activeAccount = accounts[accountSelectMenu.selectActiveAccountIndex(state)];
  }
  return activeAccount;
};

const selectSelectedGcAccountId = (state: any) => {
  const accounts = selectAccounts(state);
  if (accounts && accounts.length) {
    return accounts[accountSelectMenu.selectActiveAccountIndex(state)].gcAccountId;
  }
  return null;
};

const selectSelectedAccountIsDelinquent = (state: any) => {
  const accounts = selectAccounts(state);
  if (accounts) {
    const activeAccount = accounts[accountSelectMenu.selectActiveAccountIndex(state)];
    if (activeAccount) {
      if (activeAccount.spStatus.toLowerCase() === SpStatus.Delinquent) {
        return true;
      }
      return false;
    }
  }
  return null;
};

const selectSelectedAccountIsNotSigned = (state: any) => {
  const accounts = selectAccounts(state);
  if (accounts) {
    const activeAccount = accounts[accountSelectMenu.selectActiveAccountIndex(state)];
    if (activeAccount) {
      if (activeAccount.spStatus.toLowerCase() === SpStatus.NotSigned) {
        return true;
      }
      return false;
    }
  }
  return null;
};

const selectSelectedAccountDeedStatus = (state: any) => {
  const accounts = selectAccounts(state);
  if (accounts) {
    const activeAccount = accounts[accountSelectMenu.selectActiveAccountIndex(state)];
    if (activeAccount) {
      return activeAccount.deedStatus;
    }
  }
  return null;
};

const selectSelectedAccountTrustDeedNeedsApproval = (state: any) => {
  const accounts = selectAccounts(state);
  if (accounts) {
    const activeAccount = accounts[accountSelectMenu.selectActiveAccountIndex(state)];
    if (activeAccount && activeAccount.entityType === EntityType.Trust) {
      return activeAccount.deedStatus !== DeedStatus.Approved;
    }
  }
  return false;
};

const selectFullName = (state: any) => `${selectFirstName(state)} ${selectLastName(state)}`;

const selectMobileNumber = (state: any) => {
  const contact = selectContact(state);
  return contact ? contact.phone : '';
};

export default {
  selectPartition,
  selectJwtValues,
  selectContact,
  selectFirstName,
  selectLastName,
  selectAccounts,
  selectActiveAccount,
  selectSelectedGcAccountId,
  selectIsAllKycCompleted,
  selectFullName,
  selectSelectedAccountIsDelinquent,
  selectSelectedAccountIsNotSigned,
  selectSelectedAccountDeedStatus,
  selectSelectedAccountTrustDeedNeedsApproval,
  selectMobileNumber,
  selectReferralAccount,
};
